import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, CircularProgress, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";
import {teamMemberService} from "src/services/team/team-member";
import {TeamMemberBankDetails} from "src/entities/team/team-member";
import {useSettings} from "src/hooks/use-settings";

function isValidTurkishIdNumber(id) {
  if (id.length !== 11 || isNaN(id) || id[0] === "0") {
    return false;
  }
  const digits = id.split("").map(Number);
  const oddSum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
  const evenSum = digits[1] + digits[3] + digits[5] + digits[7];
  const tenthDigit = (oddSum * 7 - evenSum) % 10;
  const eleventhDigit = digits.slice(0, 10).reduce((a, b) => a + b, 0) % 10;

  return digits[9] === tenthDigit && digits[10] === eleventhDigit;
}

function AccountInfoBillingForm() {
  const {t} = useTranslation();
  const settings = useSettings();
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik({
    initialValues: {
      bank_full_name: "John Doe",
      bank_name: t("auth.paymentInfo.bank_name"),
      bank_iban: "",
      ibanConfirmation: "",
      bank_identity_number: "",
      bank_address: "",
    },
    validationSchema: Yup.object({
      bank_full_name: Yup.string().required(t("auth.editInfo.fullNameRequired")),
      bank_name: Yup.string().required(t("auth.paymentInfo.bankNameRequired")),
      bank_iban: Yup.string()
        .required(t("auth.paymentInfo.ibanRequired"))
        .matches(/^TR\d{2}[A-Z0-9]{22}$/, t("auth.paymentInfo.invalidIban")),
      ibanConfirmation: Yup.string()
        .required(t("auth.paymentInfo.ibanConfirmationRequired"))
        .oneOf([Yup.ref("bank_iban"), null], t("auth.paymentInfo.ibansNotMatch")),
      bank_identity_number: Yup.string()
        .required(t("auth.editInfo.identityNumberRequired"))
        .test(
          "valid-tc",
          t("auth.editInfo.invalidIdentityNumber"),
          isValidTurkishIdNumber,
        ),
      bank_address: Yup.string().required(t("auth.editInfo.addressRequired")),
    }),
    onSubmit: async values => {
      try {
        // Simulate save functionality
        console.log("Saving user data", values);
        const teamMemberBankDetailsUpdateData = new TeamMemberBankDetails(values);

        await teamMemberService
          .saveBankDetails(teamMemberBankDetailsUpdateData)
          .then(() => {
            setIsEditing(false);
            settings.showAlert("success", t("successfullyUpdated"));
          });
      } catch (err) {
        settings.showAlert("error", "Failed to save ");
        console.error("Failed to save user data:", err);
      }
    },
  });

  const {setFieldValue, handleSubmit} = formik;

  const getBankDetails = async () => {
    try {
      const bankInfo = new TeamMemberBankDetails(
        await teamMemberService.getBankDetails(),
      );

      setFieldValue("bank_full_name", bankInfo?.bank_full_name || "");
      setFieldValue("bank_name", bankInfo?.bank_name || "");
      setFieldValue("bank_iban", bankInfo?.bank_iban || "");
      setFieldValue("ibanConfirmation", bankInfo?.bank_iban || "");
      setFieldValue("bank_identity_number", bankInfo?.bank_identity_number || "");
      setFieldValue("bank_address", bankInfo?.bank_address || "");

      setLoading(false);
    } catch (err) {
      console.error("Failed to fetch team member info:", err);
    }
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        m: 3,
        mt: 0,
      }}
    >
      <Typography variant="body2">{t("auth.paymentInfo.paymentAlert")}</Typography>

      {loading ? (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {/* Full Name */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.editInfo.bank_full_name")}
            variant="outlined"
            name="bank_full_name"
            value={formik.values.bank_full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_full_name && Boolean(formik.errors.bank_full_name)}
            helperText={formik.touched.bank_full_name && formik.errors.bank_full_name}
            disabled={!isEditing}
          />

          {/* Bank Name */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.bank_name")}
            variant="outlined"
            name="bank_name"
            value={formik.values.bank_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
            helperText={formik.touched.bank_name && formik.errors.bank_name}
            disabled={!isEditing}
          />

          {/* IBAN */}
          <TextField
            sx={{width: "100%"}}
            label="IBAN (TR XX XXXX XXXX XXXX XXXX XXXX)"
            variant="outlined"
            name="bank_iban"
            value={formik.values.bank_iban}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_iban && Boolean(formik.errors.bank_iban)}
            helperText={formik.touched.bank_iban && formik.errors.bank_iban}
            disabled={!isEditing}
          />

          {/* IBAN Confirmation */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.paymentInfo.ibanConfirmation")}
            variant="outlined"
            name="ibanConfirmation"
            value={formik.values.ibanConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.ibanConfirmation && Boolean(formik.errors.ibanConfirmation)
            }
            helperText={formik.touched.ibanConfirmation && formik.errors.ibanConfirmation}
            disabled={!isEditing}
          />

          {/* Identity Number */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.editInfo.bank_identity_number")}
            variant="outlined"
            name="bank_identity_number"
            value={formik.values.bank_identity_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.bank_identity_number &&
              Boolean(formik.errors.bank_identity_number)
            }
            helperText={
              formik.touched.bank_identity_number && formik.errors.bank_identity_number
            }
            disabled={!isEditing}
          />

          {/* Address */}
          <TextField
            sx={{width: "100%"}}
            label={t("auth.editInfo.bank_address")}
            variant="outlined"
            multiline
            rows={4}
            name="bank_address"
            value={formik.values.bank_address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bank_address && Boolean(formik.errors.bank_address)}
            helperText={formik.touched.bank_address && formik.errors.bank_address}
            disabled={!isEditing}
          />

          {/* Submit Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              sx={{backgroundColor: "#ad1445", width: "100%"}}
              onClick={isEditing ? handleSubmit : () => setIsEditing(true)}
              type={isEditing ? "submit" : "button"}
            >
              {isEditing
                ? t("auth.editInfo.saveAccountInformation")
                : t("auth.editInfo.editAccountInformation")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AccountInfoBillingForm;
