import React, {useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {useTranslation} from "react-i18next";
import {projectService} from "src/services/project/project";
import {ProjectAssignment} from "src/entities/project/project-assignment";
import {TablePagination} from "@mui/material";

export const CompletedSurveys = () => {
  const {t, i18n} = useTranslation();
  const [completedProjectAssignments, setCompletedProjectAssignments] = useState([]);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);

  const [totalCompletedSurveyCount, setTotalCompletedSurveyCount] = useState(0);
  const [completedSurveysPage, setCompletedSurveysPage] = useState(1);

  useEffect(() => {
    handleCompletedSurveys(completedSurveysPage);
  }, []);

  const handleCompletedSurveys = page => {
    setCompletedSurveysPage(page);
    projectService.listProjectAssignments("completed", 1).then(response => {
      const projects = response.results.map(res => new ProjectAssignment(res));
      setTotalCompletedSurveyCount(response.count);
      setCompletedProjectAssignments(projects);
    });
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4}}>
        <Typography variant="h5" sx={{mb: 2}}>
          {t("completedSurveys.completedSurveys")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              mb: 2,
            }}
            onClick={() => setIsFailureModalOpen(true)}
            color="primary"
          >
            {t("Neden Bazı Anketler Başarısız Olarak Gözüküyor?")}
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("completedSurveys.surveyName")}</TableCell>
                <TableCell>{t("completedSurveys.completedDate")}</TableCell>
                <TableCell>{t("completedSurveys.rewardAmount")}</TableCell>
                <TableCell>{t("completedSurveys.rewardStatus")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedProjectAssignments.map(projectAssignment => (
                <TableRow key={projectAssignment.id}>
                  <TableCell>{projectAssignment.topic}</TableCell>
                  <TableCell>***</TableCell>
                  <TableCell>{projectAssignment.reward}</TableCell>
                  <TableCell>{JSON.stringify(projectAssignment.reward_status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={completedSurveysPage - 1}
          rowsPerPage={10}
          count={totalCompletedSurveyCount}
          onPageChange={(event, newPage) => {
            handleCompletedSurveys(newPage + 1);
          }}
          rowsPerPageOptions={[10]}
        />
      </Box>

      {/* Başarısız Anketler Açıklama Popup */}
      <Modal open={isFailureModalOpen} onClose={() => setIsFailureModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              textAlign: "center",
              fontWeight: "bold",
              color: "primary",
            }}
          >
            Neden Bazı Anketler Başarısız Olarak Gözüküyor?
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 2,
              textAlign: "left",
              color: "primary",
            }}
          >
            Bazı anketlerin başarısız olarak gözükmesinin sebepleri şunlar olabilir:
          </Typography>
          <Box sx={{textAlign: "left"}}>
            <ul>
              <li>
                <Typography variant="body2">
                  Algoritmamız, anketin doğru bir şekilde tamamlanmadığını tespit etmiş
                  olabilir.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Anket cevaplarının tutarsız olması veya doğruluğu şüphe uyandırmış
                  olabilir.
                </Typography>
              </li>
            </ul>
          </Box>
          <Button
            variant="contained"
            sx={{
              mt: 2,
              bgcolor: "primary",
              color: "white",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
            onClick={() => setIsFailureModalOpen(false)}
          >
            {t("Kapat")}
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};
