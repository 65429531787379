import React, {useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Grid, TablePagination} from "@mui/material";
import {useTranslation} from "react-i18next";
import {teamMemberService} from "src/services/team/team-member";
import {
  TeamMemberBankDetails,
  TeamMemberRewardRequest,
  TeamMemberRewardsStats,
} from "src/entities/team/team-member";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

export const Rewards = () => {
  const {t, i18n} = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useRouter();

  // Rewards
  const [rewardStats, setRewardStats] = useState(new TeamMemberRewardsStats({}));
  const [rewardRequests, setRewardRequests] = useState([]);
  const [totalRewardRequestCount, setTotalRewardRequestCount] = useState(0);
  const [rewardRequestPage, setRewardRequestPage] = useState(1);

  // Migros
  const [migrosButtonLoading, setMigrosButtonLoading] = useState(false);
  const [selectedMigrosAmount, setSelectedMigrosAmount] = useState(null);

  // A101
  const [a101ButtonLoading, setA101ButtonLoading] = useState(false);
  const [selectedA101Amount, setSelectedA101Amount] = useState(null);

  // IBAN
  const [ibanAmount, setIbanAmount] = useState("");
  const [isIbanModalOpen, setIsIbanModalOpen] = useState(false);
  const [bankAccountValidationCheckBox, setBankAccountValidationCheckBox] =
    useState(false);
  const [bankInfoValidationCheckBox, setBankInfoValidationCheckBox] = useState(false);
  const [transactionAccountValidationCheckbox, setTransactionAccountValidationCheckbox] =
    useState(false);
  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);

  useEffect(() => {
    teamMemberService.getTeamMemberRewardsStats().then(stats => {
      setRewardStats(new TeamMemberRewardsStats(stats));
    });
    teamMemberService.getBankDetails().then(response => {
      const details = new TeamMemberBankDetails(response);
      setBankDetails(details);
    });
    handleRewardRequests(rewardRequestPage);
  }, []);

  const handleRewardRequests = page => {
    setRewardRequestPage(page);
    teamMemberService.listTeamMemberRewardRequests(page).then(response => {
      const requests = response.results.map(req => new TeamMemberRewardRequest(req));
      setTotalRewardRequestCount(response.count);
      setRewardRequests(requests);
    });
  };

  const handleNavigateToHowToUseGiftCodes = () => {
    router.push(paths.dashboard.howtousegiftcodes);
  };

  const handleNavigateToBankDetails = () => {
    router.push(paths.dashboard.accountInfo, {state: {drawerOpen: "payment"}});
  };

  const handleReceiveGift = (rewardType, amount) => {
    const payload = {
      amount: amount,
      type: rewardType,
    };
    teamMemberService.createTeamMemberRewardRequests(payload).then(response => {
      console.log(response);
    });
    rewardStats.decrementAvailableRewardAmount(amount);
    setRewardStats(rewardStats.clone());
  };

  const handleIbanChange = event => {
    setIbanAmount(event.target.value);
  };

  const selectAmount = (setSelectedAmount, amount) => {
    if (rewardStats.available_reward_amount >= amount) {
      setSelectedAmount(amount);
    }
  };

  const handleOpenIbanModal = () => {
    setIsIbanModalOpen(true);
  };

  const handleCloseIbanModal = () => {
    setIsIbanModalOpen(false);
    setBankAccountValidationCheckBox(false);
    setBankInfoValidationCheckBox(false);
    setTransactionAccountValidationCheckbox(false);
  };

  const handleIbanConfirm = () => {
    console.log(`IBAN'a gönderildi: ${ibanAmount}`);
    handleCloseIbanModal();
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4}}>
        {/* Points Boxes */}
        <Grid container spacing={isMobile ? 2 : 4} sx={{mb: 4}}>
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.availablePoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.available_reward_amount}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.pendingPoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.pending_reward_amount}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.usedPoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.used_reward_amount}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Sağ hizalama
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              mb: 2,
            }}
            onClick={() => setIsExplanationModalOpen(true)} // Popup açma
            color="primary"
          >
            {t("Eksik Puanın Olduğunu Mu Düşünüyorsun?")}
          </Button>
        </Box>

        {/* Eksik Puanlar Açıklama Popup */}
        <Modal
          open={isExplanationModalOpen}
          onClose={() => setIsExplanationModalOpen(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: "center",
                fontWeight: "bold",
                color: "primary.main",
              }}
            >
              Eksik Puanlar Hakkında
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 2,
                textAlign: "left",
                color: "text.secondary",
              }}
            >
              Eksik puanlarınız birkaç sebepten kaynaklanıyor olabilir:
            </Typography>
            <Box sx={{textAlign: "left"}}>
              <ul>
                <li>
                  <Typography variant="body2">
                    Algoritmamız, soruları tam olarak okumadığınızı tespit etmiş olabilir.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Cevaplama süresinin kısa olması, doğru bir değerlendirme yapılmasına
                    engel olmuş olabilir.
                  </Typography>
                </li>
              </ul>
            </Box>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                bgcolor: "primary.main",
                color: "white",
                "&:hover": {
                  bgcolor: "primary.dark",
                },
              }}
              onClick={() => setIsExplanationModalOpen(false)} // Popup kapatma
            >
              {t("Kapat")}
            </Button>
          </Box>
        </Modal>

        {/* Rewards Section */}
        <Typography variant="h5" sx={{mb: 2}}>
          {t("rewards.rewards")}
        </Typography>
        <Button onClick={handleNavigateToHowToUseGiftCodes} color="primary">
          {t("Hediye Kodları Nasıl Kullanılır?")}
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "space-between",
            gap: 2,
            mb: 4,
          }}
        >
          {/* Migros Card */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              width: isMobile ? "100%" : "30%",
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">Migros {t("rewards.giftCard")}</Typography>
            <Box sx={{display: "flex", justifyContent: "center", gap: 1, mt: 2}}>
              {[100, 200, 300].map(amount => (
                <Button
                  key={amount}
                  variant={selectedMigrosAmount === amount ? "contained" : "outlined"}
                  onClick={() => selectAmount(setSelectedMigrosAmount, amount)}
                  disabled={rewardStats.available_reward_amount < amount}
                >
                  {amount}
                </Button>
              ))}
            </Box>
            <LoadingButton
              disabled={
                selectedMigrosAmount === null ||
                rewardStats.available_reward_amount < selectedMigrosAmount
              }
              loading={migrosButtonLoading}
              variant="contained"
              onClick={() => handleReceiveGift("migros", selectedMigrosAmount)}
              sx={{mt: 2}}
            >
              {t("rewards.receiveGifts")}
            </LoadingButton>
          </Box>

          {/* A101 Card */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              width: isMobile ? "100%" : "30%",
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">A101 {t("rewards.giftCard")}</Typography>
            <Box sx={{display: "flex", justifyContent: "center", gap: 1, mt: 2}}>
              {[100, 200, 300].map(amount => (
                <Button
                  key={amount}
                  variant={selectedA101Amount === amount ? "contained" : "outlined"}
                  onClick={() => selectAmount(setSelectedA101Amount, amount)}
                  disabled={rewardStats.available_reward_amount < amount}
                >
                  {amount}
                </Button>
              ))}
            </Box>
            <LoadingButton
              disabled={
                selectedA101Amount === null ||
                rewardStats.available_reward_amount < selectedA101Amount
              }
              loading={a101ButtonLoading}
              variant="contained"
              onClick={() => handleReceiveGift("a101", selectedA101Amount)}
              sx={{mt: 2}}
            >
              {t("rewards.receiveGifts")}
            </LoadingButton>
          </Box>

          {/* Iban Payment Section */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              mx: "auto",
              width: "30%",
            }}
          >
            <Typography variant="h6">Send to IBAN</Typography>
            <TextField
              sx={{mt: 2, width: "100%"}}
              disabled={!(bankDetails && bankDetails.isValid())}
              size="small"
              variant="outlined"
              label="Amount to Send"
              value={ibanAmount}
              onChange={handleIbanChange}
              type="number"
              onInput={e => {
                e.target.value = e.target.value < 0 ? 0 : e.target.value; // Negatif sayıları engeller
              }}
              helperText={
                bankDetails && bankDetails.isValid()
                  ? ibanAmount >= 100
                    ? `Toplam gönderim miktarı: ${
                        parseInt(ibanAmount) + Math.floor(ibanAmount / 100) * 10
                      } TL`
                    : "Toplu talep et daha çok kazan! 100 ve üzeri taleplerinizde 10 TL ekstra veriyoruz"
                  : ""
              }
              error={ibanAmount > 0 && ibanAmount < 100} // Hata stili 100 TL'den düşükse
            />
            {bankDetails ? (
              bankDetails.isValid() ? (
                <Button
                  variant="contained"
                  sx={{mt: 2}}
                  disabled={!ibanAmount} // Sadece giriş yapılmazsa buton pasif olur
                  onClick={() => {
                    const totalAmount =
                      parseInt(ibanAmount) + Math.floor(ibanAmount / 100) * 10;
                    console.log(`Gönderilen miktar: ${ibanAmount}`);
                    console.log(`Hediye ile gönderilen miktar: ${totalAmount} TL`);
                    handleOpenIbanModal();
                  }}
                >
                  Receive Gifts
                </Button>
              ) : (
                <Button
                  sx={{mt: 2}}
                  variant="contained"
                  onClick={handleNavigateToBankDetails}
                >
                  Please Enter Your Bank Details
                </Button>
              )
            ) : (
              <CircularProgress size={24} color="inherit" />
            )}
          </Box>

          {/* Modal */}
          <Modal open={isIbanModalOpen} onClose={handleCloseIbanModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                p: 4,
                borderRadius: 3,
                boxShadow: 24,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "primary.main",
                }}
              >
                IBAN Onayı
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mb: 3,
                  textAlign: "center",
                  color: "text.secondary",
                }}
              >
                Lütfen aşağıdaki koşulları kabul ettiğinizden emin olun.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bankAccountValidationCheckBox}
                    onChange={e => setBankAccountValidationCheckBox(e.target.checked)}
                  />
                }
                label="Para göndermek istediğim banka hesabı bana aittir."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bankInfoValidationCheckBox}
                    onChange={e => setBankInfoValidationCheckBox(e.target.checked)}
                  />
                }
                label="Girilen bilgilerde hata olması durumunda işlemin gerçekleşmeyebileceğini ve bunun sonuçlarından sorumlu olacağımı anlıyorum."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={transactionAccountValidationCheckbox}
                    onChange={e =>
                      setTransactionAccountValidationCheckbox(e.target.checked)
                    }
                  />
                }
                label="Girdiğim IBAN numarası vadesiz banka hesabıdır. Bunun haricindeki yerlere (vadeli banka hesabı, PTT posta çeki hesabı, kredi kartı ve ATM kartı gibi) yapılan işlemlerin gerçekleşmeyeceğini anlıyorum."
              />
              <LoadingButton
                sx={{
                  mt: 3,
                  bgcolor: "primary.main",
                  color: "white",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
                variant="contained"
                disabled={
                  !(
                    bankAccountValidationCheckBox &&
                    bankInfoValidationCheckBox &&
                    transactionAccountValidationCheckbox
                  )
                } // Üç checkbox işaretlenmeli
                onClick={() => {
                  const totalAmount =
                    parseInt(ibanAmount) + Math.floor(ibanAmount / 100) * 10;
                  console.log(`Gerçekleşen işlem miktarı: ${totalAmount} TL`);
                  handleIbanConfirm();
                }}
              >
                {t("rewards.receiveGifts")}
              </LoadingButton>
            </Box>
          </Modal>

          {/* Previous Reward Requests Section */}
          <Typography variant="h6" sx={{mb: 2}}>
            {t("rewards.previousRewardRequests")}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("rewards.requestedStatus")}</TableCell>
                  <TableCell>{t("rewards.pointUsed")}</TableCell>
                  <TableCell>{t("rewards.rewardType")}</TableCell>
                  <TableCell>{t("rewards.giftCode")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rewardRequests.length > 0 ? (
                  rewardRequests.map((request, index) => (
                    <TableRow key={index}>
                      <TableCell>{request.status}</TableCell>
                      <TableCell>{request.amount}</TableCell>
                      <TableCell>{request.type}</TableCell>
                      <TableCell>{request.code}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      {t("rewards.noRequests")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            page={rewardRequestPage - 1}
            rowsPerPage={10}
            count={totalRewardRequestCount}
            onPageChange={(event, newPage) => {
              handleRewardRequests(newPage + 1);
            }}
            rowsPerPageOptions={[10]}
          />
        </Box>
      </Box>
    </Container>
  );
};
